<template>
  <div id="app">
    <component :is="componentName" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    window.baseInfo = this.$root.base;
  },
  computed: {
    componentName() {
      if (this.$root.base.index === 0) {
        return 'Internal';
      }
      return 'Website';
    }
  },
  components: {
    Internal: () => import('@/views/internal/Index.vue'),
    Website: () => import('@/views/website/Index.vue')
  }
};
</script>

<style>
#app {
  transform: translateZ(0);
}
button {
  padding: 0;
  outline: none;
  border: transparent;
}
p,
ul,
li {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
</style>
