const types = [
  {
    imageList: [
      'https://f2.kkmh.com/game/211129/coQNvm5Rk.jpeg',
      'https://f2.kkmh.com/game/211129/pYHxOa4DH.jpeg',
      'https://f2.kkmh.com/game/211129/BRZNbpBpF.jpeg',
      'https://f2.kkmh.com/game/211129/3lNrOS9D7.jpeg',
      'https://f2.kkmh.com/game/211129/Y0PaDrsAk.jpeg',
      'https://f2.kkmh.com/game/211129/8ScPMOiut.jpeg',
      'https://f2.kkmh.com/game/211129/MYR0CdcZw.jpeg',
      'https://f2.kkmh.com/game/211129/Mf8JenDdh.jpeg'
    ],
    displayImageList: [
      'https://f2.kkmh.com/game/220207/vXjHLOASd.jpeg',
      'https://f2.kkmh.com/game/220207/OXF6tM3rh.jpeg',
      'https://f2.kkmh.com/game/220207/y1WDcgySC.jpeg'
    ],
    newsSliderImageList: [
      'https://f2.kkmh.com/game/211129/BqgH2ZA32.jpeg',
      'https://f2.kkmh.com/game/211129/oyyu1uXcS.jpeg',
      'https://f2.kkmh.com/game/211129/ruIPO0l0w.jpeg',
      'https://f2.kkmh.com/game/211129/e7WGpzJOj.jpeg'
    ],
    news: [
      {
        title: '“冰桶挑战”：席卷全球的公益病毒',
        content:
          '参加者只需将一桶冰水从头向下浇下，或许向美国ALS协会捐献100美元。成功完成应战的人能够揭露点名3自己参加应战，点名者要么在24小时内应战，要么向美国ALS协会捐款100美元，以此继续接力。两周内，冰桶应战风行美国，变成交际媒体的热门论题，Facebook创始人马克-扎克伯格、富豪比尔-盖茨、微软CEO纳德拉、苹果CEO蒂姆-库克及篮球明星、交际名媛等各界名人纷繁参加。ALS敏捷进入美国公众视界。'
      },
      {
        title: '《后会无期》：国民岳父教你做营销',
        content:
          ' 1月31日，韩寒在微博上发布了一张小野的相片，有网友在回复中称韩寒为“岳父大人”，韩寒将该条回复转发到微博上，“国民岳父”的美名自此敏捷走红。阿信、冯绍峰等明星也竞相在微博上称韩寒为岳父，有人还拓荒了“国民岳父韩寒”的微博论题，该论题的评论量亦超越10万，阅览量则达到121万。“听过许多道理，仍然过欠好这一生”的宣传语既是对影片主题的归纳，一起也是韩寒文字作品的一种连续。影片商场风云叵测，《后会无期》有也许兵败垂成，但只要品牌在，韩寒随时能够东山再起。“国民岳父”韩寒，更可谓“推行教父”。'
      },
      {
        title: '农夫山泉，甜并快乐着',
        content:
          '农民山泉在这个时分切入商场，并在短短几年内反抗住了很多国内外品牌的冲击，稳居职业三甲，成功要素之一在于其差异化推行之策。而差异化的直接体现来自于“有点甜”的概念构思——“农民山泉有点甜”。农民山泉”真的有点甜吗?非也，推行传达概念罢了。农民山泉的水来自千岛湖，是从许多大山中汇总的泉流，通过千岛湖的自净、净化，完全能够说是香甜的泉流。但怎样才干让消费者直观形象地认识到农民山泉的“身世”，怎样形成夸姣的“甘泉”形象?这就需求一个简略而形象的推行传达概念。'
      }
    ]
  },
  {
    imageList: [
      'https://f2.kkmh.com/game/230131/YSIp6Fg9J.jpg',
      'https://f2.kkmh.com/game/230131/IEI9jsWgj.jpg',
      'https://f2.kkmh.com/game/230131/bR05CwkmT.jpg',
      'https://f2.kkmh.com/game/230131/JIbM5VYiE.jpg',
      'https://f2.kkmh.com/game/230131/xrH2cBT4d.jpg',
      'https://f2.kkmh.com/game/230131/XX7TIdCYb.jpg',
      'https://f2.kkmh.com/game/230131/tETRvs3nw.jpg',
      'https://f2.kkmh.com/game/230131/KzlOxhpec.jpg'
    ],
    displayImageList: [
      'https://f2.kkmh.com/game/230131/ry8un6HSv.jpg',
      'https://f2.kkmh.com/game/230131/xCtuhftd8.jpg',
      'https://f2.kkmh.com/game/230131/UDV7mGdXl.jpg'
    ],
    news: [
      {
        title: '安徽芜湖：格桑花争奇斗艳',
        content:
          '秋风送爽，百花盛放，在安徽省芜湖市繁昌区繁阳镇范马村林乐慢谷农业种植基地，格桑花儿竞相开放，朵朵争奇斗艳，美不胜收，吸引众多游客纷至沓来，享受美丽花海盛宴。'
      },
      {
        title: '新疆蘑菇湖水库：碧水蒹葭　群鸟翔集',
        content:
          '而位于新疆兵团第八师石河子市西北21公里处的蘑菇湖水库，是一座大型平原水库。深秋时节的蘑菇湖湿地，碧水蓝天，飞鸟翔集，美不胜收。水库总库容1.8亿立方米，水面面积31.2平方公里，控制灌溉面积近200万亩。在蘑菇湖水库工作了37年的王云清说，如今的蘑菇湖水清了，湿地也美了，周边的环境也越来越好了。'
      },
      {
        title: '山西平顺：虹霓河畔映“霓虹”',
        content:
          '随着夜幕慢慢降临，走在虹霓河畔，静谧的虹霓河水在景观灯的点缀下，与倒影互相映衬着河两岸的万家灯火、流光溢彩，构成了一幅幅五彩缤纷的美丽生态画卷，漫步其中，犹如幻境，仿佛置身于童话世界，让人流连忘返。'
      }
    ],
    newsSliderImageList: [
      'https://f2.kkmh.com/game/211129/ruIPO0l0w.jpeg',
      'https://f2.kkmh.com/game/211129/oyyu1uXcS.jpeg',
      'https://f2.kkmh.com/game/211129/BqgH2ZA32.jpeg',
      'https://f2.kkmh.com/game/211129/e7WGpzJOj.jpeg'
    ]
  },
  {
    imageList: [
      'https://f2.kkmh.com/game/230131/4vWfYHRNt.jpg',
      'https://f2.kkmh.com/game/230131/3WINs9NNL.jpg',
      'https://f2.kkmh.com/game/230131/3brs6TwMn.jpg',
      'https://f2.kkmh.com/game/230131/NewAZh9mG.jpg',
      'https://f2.kkmh.com/game/230131/dIEpSQDQb.jpg',
      'https://f2.kkmh.com/game/230131/yQqxUq5jq.jpg',
      'https://f2.kkmh.com/game/230131/ZMditKoFs.jpg',
      'https://f2.kkmh.com/game/230131/E7fo05ZQt.jpg'
    ],
    displayImageList: [
      'https://f2.kkmh.com/game/230131/4Q5NKsTs9.jpg',
      'https://f2.kkmh.com/game/230131/oN1pdwPWj.jpg',
      'https://f2.kkmh.com/game/230131/d12bNyfVB.jpg'
    ],
    news: [
      {
        title: '用好这4个摄影技巧，轻松记录你的城市风光',
        content:
          '1）地标性建筑：许多城市都有地标性建筑，而且地标建筑都很有特色，相信你所在的城市一定有，不妨去拍拍试试。2）河流、桥梁：一般城市都会有河流和桥梁，河流贯穿整座城市，河岸的风光很漂亮，桥梁也是很好的拍摄基地。3）湖泊、湿地公园：城市里的各个公园、湖泊都是很不错的休闲场所，也是很不错的拍摄场地，不管是远处的游船，还是近处的花花草草，都是很好的拍摄题材。4）古建筑：一些城市会有一些保存完好的古建筑，我们可以过去逛逛，顺便用来拍摄一些有韵味的摄影题材。'
      },
      {
        title: '摄影机位应该如何选择？',
        content:
          '在城市风光摄影中，拍摄位置的选择很重要，它几乎决定了一张照片的好坏。对于建筑物，我们可以采用仰拍视角，而如果场景内杂物较多，则可以选择高角度拍摄城市美景。在风光照中出现人物，总是一件让人不爽的事，不过在拍摄城市风光时就不太一样了，有时我们需要用镜头表现出城市中不同姿态的人们。拍城市风光不一定要拍那些大场景，善于抓拍大场景的细节，也是很不错的选择。城市风光细节很多，可以是一个特写，也可以是一件趣事。总之，摄影师要学会从细处着眼。城市风光摄影中最大的难度就是保证前景、中景和背景的好看，起码不能有与主题无关的杂物出现，所以摄影师在拍摄时一定要注意观察画面，尽量保证摄影画面好看一些。每个城市都有属于它的独特美，就看作为摄影师的你，能不能用镜头记录下来。以上就是今天分享给大家的全部内容啦，希望对大家拍摄城市风光有帮助。'
      },
      {
        title: '都市夜景的拍摄方法',
        content:
          '1. 精心挑选拍摄对象。2. 俗话说：“工欲善其事，必先利其器”。拍摄城市夜景，相机最好选择全画幅，高感和对焦性能要强的相机。其次是镜头，以 14mm 到 24mm 焦段为宜。3. 准确把握拍摄时机, 根据我的经验，一年中拍摄城市夜景最好的拍摄时间主要集中在夏、秋两季，一般在 6 月下旬到 10 月上旬这段时间。从每一天的情况看，如果选择在清晨拍摄，则以早上 4 点左右较为适当。这时天还没亮，但天空已呈现出蓝调，东方刚刚泛起一丝弱光，城市的华灯尚未退去，此时虽然是清晨，但仍然能拍出城市夜景的感觉。'
      }
    ],
    newsSliderImageList: ['https://f2.kkmh.com/game/230131/eXnNSRo8f.jpg']
  },
  {
    imageList: [
      'https://f2.kkmh.com/game/230131/5VIUU6yQH.jpg',
      'https://f2.kkmh.com/game/230131/FeZPYboWn.jpeg',
      'https://f2.kkmh.com/game/230131/vySoHeBrN.jpg',
      'https://f2.kkmh.com/game/230131/i01CT7U15.jpg',
      'https://f2.kkmh.com/game/230131/2k5CzkwZy.jpeg',
      'https://f2.kkmh.com/game/230131/3dxv56OKC.jpeg',
      'https://f2.kkmh.com/game/230131/tw7MhfnAv.jpg',
      'https://f2.kkmh.com/game/230131/L7VF9DDHh.jpeg'
    ],
    displayImageList: [
      'https://f2.kkmh.com/game/230131/6CIdSPJ7t.jpeg',
      'https://f2.kkmh.com/game/230131/3TkFvxQZt.jpeg',
      'https://f2.kkmh.com/game/230131/HPq6fyjR3.webp'
    ],
    news: [
      {
        title: '木心美术馆',
        content:
          '木心先生本人一生都追逐美、审视美，甚至挑剔美，但他在临终的时候看到设计稿时说：“风啊，水啊，一顶桥。”于是，乌镇环湖而造的木心美术馆落成，由陈丹青任馆长。美术馆设计遵循木心先生生前喜欢的简约美学，由建筑大师贝聿铭弟子冈本博等人设计。馆内乱石与野草呼应，疯狂又典雅。'
      },
      {
        title: '西岸美术馆',
        content:
          '美术馆地块呈三角形，位于宽阔的滨江绿化带的最北端，围绕该建筑的地面广场被抬升至洪泛区之上，黄浦江景致一览无余。立面材质将斑斓的日光带入建筑，夜间也透出绚丽的灯光，与悬挑屋顶光洁的质感涂料饰面形成鲜明对比。'
      },
      {
        title: 'UCCA沙丘美术馆',
        content:
          '这座消隐的美术馆，如同藏于沙丘之下的神秘洞穴。海岸沙丘经历了漫长的时间累积和风沙推移而自然形成，并被原生低矮灌木深扎的根系固化下来。建筑师保留了混凝土壳体上留下的不规则甚至不完美的肌理，让手工建造的痕迹可以被触摸、被感知。'
      }
    ],
    newsSliderImageList: ['https://f2.kkmh.com/game/230131/rsRmAq4SF.jpg']
  },
  {
    imageList: [
      'https://f2.kkmh.com/game/230131/asKSs2ami.jpg',
      'https://f2.kkmh.com/game/230131/TPiIYr99z.jpg',
      'https://f2.kkmh.com/game/230131/iLVrQy8Bb.jpg',
      'https://f2.kkmh.com/game/230131/0PxyJi9hO.jpg',
      'https://f2.kkmh.com/game/230131/JG3fb4eua.jpg',
      'https://f2.kkmh.com/game/230131/8gQwg8lxc.jpeg',
      'https://f2.kkmh.com/game/230131/6bHJG0dqZ.jpeg',
      'https://f2.kkmh.com/game/230131/syQtJUCS7.jpg'
    ],
    displayImageList: [
      'https://f2.kkmh.com/game/230131/KWbehsjLV.jpg',
      'https://f2.kkmh.com/game/230131/CyN3npc1M.jpg',
      'https://f2.kkmh.com/game/230131/omm7efpGA.jpeg'
    ],
    news: [
      {
        title: '东山岛沙滩',
        content:
          '东山岛位于福建东山县，由东山主岛和33个小岛组成，从汕头市驱车两个多小时便可到东山半岛。一牙新月般的海湾环绕着美丽的东山半岛，乌礁湾、东沈湾、马銮湾三湾相连，各具特色。东山岛的沙滩都很美，乌礁湾是当地特别保护渔湾，以自然美著称，它的沙滩沙子细软如绵白糖。海风拂过，一层层薄沙打着转儿轻轻飘起。随着海水涌上岸来的鱼蟹、海螺，也许也留恋这细软的沙滩而忘了归程。'
      },
      {
        title: '亚龙湾沙滩',
        content:
          '享有“天下第一湾”的亚龙湾是三亚市东郊的一处优质热带海滨风景区，海水是三亚五大湾区中最好的。亚龙湾的沙滩平缓开阔，沙粒洁白细软，海水清澈澄莹，海底资源丰富，生长着众多原始热带植被，颇具热带海岛风情 。亚龙湾为一个月牙湾，拥有7千米长的银白色海滩，沙质相当细腻。而这里的南海没有受到污染，海水洁净透明，远望呈现几种不同的蓝色，可开展多种水上运动。'
      },
      {
        title: '大鹏半岛海滩',
        content:
          '大鹏半岛海滩位于深圳龙岗区东部，东临大亚湾，与惠州接壤，西抱大鹏湾，遥望香港新界，三面环海，多为花岗岩山地，山势陡峭，山谷幽深，奇峰怪石众多，大鹏半岛沿岸分布着大大小小十几个沙滩，如金沙湾、西涌、东涌、桔钓沙等，这些沙滩沙质松软，属中细沙，湾内水深较大，这使得大鹏半岛的沙滩除能承担传统的功能外，还可以冲浪、帆板，为越来越多喜爱甚至尝试新兴运动项目的游客服务。'
      }
    ],
    newsSliderImageList: ['https://f2.kkmh.com/game/230131/H4eA5zb9O.jpg']
  }
];

export default types;
