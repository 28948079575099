<template>
  <iframe :src="src" frameborder="0" width="100%" :height="height" />
</template>
<script>
export default {
  data() {
    return {
      height: window.innerHeight
    };
  },
  computed: {
    src() {
      return `/template-${this.$root.base.index}/index.html`;
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
    document.title = this.$root.base.title;
    loading.hide();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.height = window.innerHeight;
    }
  }
};
</script>
<style scoped></style>
