<template>
  <div>
    <Header />
    <div class="index" :style="{ minHeight: height }">
      <div class="main-body">
        <swiper :slides-per-view="3" :space-between="50" class="banner-img-wrap">
          <swiper-slide class="banner-img">
            <img class="banner-img" src="https://f2.kkmh.com/game/220111/GWxZQr4Px.jpeg" alt="" />
          </swiper-slide>
        </swiper>
        <div class="main-wrap">
          <div class="main-item main-left_comp">
            <img src="./assets/1.jpeg" alt="" />
            <div class="sub-title">关于我们</div>
            <div class="sub-desc">{{ baseInfo.compDesc }}</div>
          </div>
          <div class="main-item main-right_desc">
            <img src="./assets/18.jpeg" alt="" />
            <div class="sub-title">经营范围</div>
            <div class="sub-desc">
              {{ baseInfo.businessDesc }}
            </div>
          </div>
        </div>
        <h2 class="title-h2">最新内容</h2>
        <div class="sub-content">
          <swiper class="sub-content-wrap">
            <swiper-slide v-for="item in newsSliderImageList" :key="item" class="sub-content-img">
              <img class="sub-content-img" :src="item" alt="" />
            </swiper-slide>
          </swiper>
          <div class="sub-news">
            <div class="sub-new-item" v-for="(item, index) in news" :key="index">
              <a class="sub-news-title" href="#">{{ item.title }}</a>
              <div class="sub-news-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <h2 class="title-h2">图片赏析</h2>
        <div class="content">
          <img
            v-lazyLoad
            v-for="url in imageList"
            :key="url"
            class="img-item"
            :data-src="url | compress(300, true)"
            alt=""
          />
        </div>
        <h2 class="title-h2">展示风采</h2>
        <div class="content">
          <img
            v-lazyLoad
            v-for="url in displayImageList"
            :key="url"
            class="img-item-2"
            :data-src="url | compress(300, true)"
            alt=""
          />
        </div>
      </div>
      <footer class="footer">
        <p>网站所属: {{ baseInfo.wapBelongs }}</p>
        <p>
          备案号：<a :href="baseInfo.recordHref">{{ baseInfo.recordId }}</a>
        </p>
      </footer>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import Header from './Header.vue';
import types from './types';

export default {
  name: 'Home',
  components: {
    Header,
    swiper,
    swiperSlide
  },
  computed: {
    baseInfo() {
      return this.$root.base;
    }
  },
  data() {
    return {
      height: window.innerHeight + 'px',
      imageList: [],
      news: [],
      displayImageList: [],
      newsSliderImageList: []
    };
  },
  created() {
    document.title = this.baseInfo.documentTitle;

    const info = types[this.baseInfo.type] || {};
    const defaultInfo = types[0];

    this.imageList = info.imageList || defaultInfo.imageList;
    this.news = info.news || defaultInfo.news;
    this.displayImageList = info.displayImageList || defaultInfo.displayImageList;
    this.newsSliderImageList = info.newsSliderImageList || defaultInfo.newsSliderImageList;
    loading.hide();
  }
};
</script>
<style scoped>
.index {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
}
.main-body {
  padding: 0 80px 0;
}
.content {
  background: transparent;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
}
.img-item {
  width: 20%;
  margin-right: 5%;
  margin-bottom: 5%;
}
.main-wrap {
  display: flex;
  padding: 40px 20px;
  justify-content: space-around;
}
.main-left_comp {
  width: 30%;
}
.main-item {
  text-align: center;
}
.main-item img {
  width: 300px;
  height: 215px;
  object-fit: contain;
}
.sub-title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}
.sub-desc {
  color: #333;
  text-align: left;
  text-indent: 2em;
  font-size: 16px;
}
.main-right_desc {
  width: 50%;
  font-size: 14px;
}
.footer {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  padding: 30px 0;
  color: #8b939d;
  background: #1b252e;
  line-height: 1.5;
  margin-top: 40px;
}
.footer a {
  text-decoration: none;
  color: #8b939d;
}
.banner-img-wrap {
  height: 300px;
  margin: 30px auto 20px;
}
.banner-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.img-item-2 {
  width: 30%;
  margin-right: 3%;
  margin-bottom: 3%;
  height: 20vw;
  object-fit: cover;
}
.img-item-3 {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.sub-content {
  display: flex;
  height: 300px;
  padding: 20px 60px 20px 20px;
  overflow: hidden;
}
.sub-content-wrap {
  height: 300px;
  width: 400px;
  flex-grow: 0;
}
.sub-news {
  flex: 1;
  padding: 0 20px;
}
.sub-new-item {
  margin-bottom: 20px;
}
.sub-news-title {
  font-size: 16px;
}
.sub-content-img {
  width: 400px;
  height: 300px;
  object-fit: cover;
  display: inline-block;
}
.sub-news-title {
  text-decoration: none;
  color: #323232;
  font-weight: 700;
}
.sub-news-content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #646464;
  margin-top: 10px;
}
</style>
