/**
 * 全局共享数据，初始化全局配置
 */
import { webview } from '@kk/utils';

let listenerParam;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function () {
      listenerParam = { passive: true };
      return true;
    }
  });
  addEventListener('test', null, opts);
} catch (e) {
  listenerParam = false;
}

export default {
  listenerParam,
  isKuaikan: webview.isKuaikan
};
