/* eslint-disable sonarjs/no-duplicate-string */
const qsyyInfos = [
  'www.qsyyfx.com',
  'www.yymhfx.com',
  'www.fxqsyy.com',
  'fxyymh.com',
  'www.fxyymh.com',
  'qsmhfx.com',
  'www.qsmhfx.com'
].map((domain, index) => {
  return {
    domain,
    documentTitle: '青色烟雨',
    title: '广州青色烟雨传媒科技有限公司',
    wapBelongs: '广州青色烟雨传媒科技有限公司',
    recordId: '粤ICP备2021154668号',
    recordHref: 'https://beian.miit.gov.cn',
    compDesc:
      '我司广州青色烟雨传媒科技有限公司成立于2021年08月30日，注册地位于广州市天河区五山路371-1号楼28楼A1869号（仅限办公），法定代表人为郭志恋。',
    businessDesc: `我司的主要经营范围包括音像制品出租;广告发布（非广播电台、电视台、报刊出版单位）;广告设计、代理;品牌管理;社会经济咨询服务;信息技术咨询服务;摄像及视频制作服务;
    数字内容制作服务（不含出版发行）;广告制作;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;图文设计制作;广告发布（广播电台、电视台、报刊出版单位）;
    广播电视节目制作经营;电视剧制作;网络文化经营;音像制品制作;互联网信息服务;`,
    type: index % 5,
    index
  };
});

const hzlxInfos = [
  'fxhzlx.com',
  'hlmhfx.com',
  'lxmhfx.com',
  'hzlxmh.com',
  'mhhzlx.com',
  'aidraw.vip',
].map((domain, index) => {
  return {
    domain,
    documentTitle: '慧智兰心',
    title: '广州慧智兰心传媒科技有限公司',
    wapBelongs: '广州慧智兰心传媒科技有限公司',
    recordId: `粤ICP备2021172343号-${index + 1}`,
    recordHref: 'https://beian.miit.gov.cn',
    compDesc:
      '我司广州慧智兰心传媒科技有限公司成立于2021年08月30日，注册地位于广州市天河区五山路371-1号楼28楼A1870号（仅限办公），法定代表人为郭志恋。',
    businessDesc: `我司的主要经营范围包括广告制作;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;图文设计制作;数字内容制作服务（不含出版发行）;摄像及视频制作服务;
    信息技术咨询服务;社会经济咨询服务;品牌管理;音像制品出租;广告发布（非广播电台、电视台、报刊出版单位）;广告设计、代理;;音像制品制作;网络文化经营;电视剧制作;
    广播电视节目制作经营;广告发布（广播电台、电视台、报刊出版单位）;互联网信息服务;`,
    type: 0,
    index
  };
});

const qyjyInfos = ['www.qyjyfx.com', 'fxqyjy.com', 'qyjyfx.com'].map((domain, index) => {
  return {
    domain,
    documentTitle: '七月骄阳',
    title: '天津七月骄阳文化传媒有限公司',
    wapBelongs: '天津七月骄阳文化传媒有限公司',
    recordId: `津ICP备2022000215号-${index + 1}`,
    recordHref: 'https://beian.miit.gov.cn',
    compDesc:
      '我司天津七月骄阳文化传媒有限公司成立于2021年11月30日，注册地位于天津市河西区解放南路与浦口道交口东南侧创展大厦-7I12，法定代表人为屈潇。',
    businessDesc: `我司主要的经营范围包括一般项目：组织文化艺术交流活动；广告设计、代理；广告制作；广告发布。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）
    许可项目：互联网信息服务；第二类增值电信业务；出版物零售；出版物互联网销售；网络文化经营。（依法须经批准的项目，经相关部门批准后方可开展经营活动，
    具体经营项目以相关部门批准文件或许可证件为准）`,
    type: 0,
    index
  };
});

const syqfInfos = ['www.syqffx.com'].map((domain, index) => {
  return {
    domain,
    documentTitle: '三月轻风',
    title: '北京三月轻风文化传媒有限公司',
    wapBelongs: '北京三月轻风文化传媒有限公司',
    recordId: `京ICP备2022001306号-${index + 1}`,
    recordHref: 'https://beian.miit.gov.cn',
    compDesc:
      '我司北京三月轻风文化传媒有限公司成立于2021年12月06日，注册地位于北京市海淀区丰润中路5号院79号楼2层106-9，法定代表人为项洁。',
    businessDesc: `我司的主要经营范围包括组织文化艺术交流活动（不含营业性演出）；设计、制作、代理、发布广告；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；
    互联网信息服务；网络文化经营；出版物零售；第二类增值电信业务。（市场主体依法自主选择经营项目，开展经营活动；互联网信息服务、网络文化经营、出版物零售、
    第二类增值电信业务以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）`,
    type: 0,
    index
  };
});

const infos = [...qsyyInfos, ...hzlxInfos, ...qyjyInfos, ...syqfInfos];

function getDomainInfo() {
  const host = infos.find((item) => {
    if (item.domain.startsWith('www')) {
      return item.domain === document.location.host;
    }

    return new RegExp(`^(www\\.)?${item.domain}`).test(document.location.host);
  });

  return host || qsyyInfos[0];
}

export { getDomainInfo };
