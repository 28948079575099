<template>
  <div class="wap-header">
    <div class="left-wrap_title">
      {{ base.title }}
    </div>
    <div class="right-wrap_tabs">
      <div
        @click="toActive(tab)"
        class="item_tabs"
        :class="{ active: curTab === tab.name }"
        v-for="tab in tabs"
        :key="tab.name"
      >
        {{ tab.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      tabs: [
        { name: '首页' }
        // {name: '关于我们'},
        // {name: '联系我们'}
      ]
    };
  },
  computed: {
    base() {
      return this.$root.base;
    },
    curTab() {
      return this.$parent.tab;
    }
  },
  methods: {
    toActive(tab) {
      this.$parent.tab = tab.name;
    }
  }
};
</script>

<style scoped>
.wap-header {
  height: 80px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
}
.left-wrap_title {
  font-size: 30px;
  font-weight: 700;
}
.right-wrap_tabs {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_tabs {
  font-size: 20px;
  padding: 10px 20px;
  font-size: 700;
}
.item_tabs.active {
  background: #e8a659;
  color: #ffffff;
}
</style>
