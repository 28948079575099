import Vue from 'vue';
import App from './App.vue';
import globalData from './global';
import lazyload from '@kk/vue-lazy';
import tap from '@kk/tap';
import {compress} from '@kk/utils';
import {getDomainInfo} from './baseInfo.js';


tap(document.body);
Vue.use(lazyload);

Vue.config.productionTip = false;

Vue.mixin({
  data: () => globalData,
  filters: {
    compress
  },
  methods: {
    sa (eventName, param) {
      kksa.track(eventName, {
        ...param
      });
    }
  }
});

async function init () {
  new Vue({
    data: {
      base: getDomainInfo()
    },
    render: h => h(App)
  }).$mount('#app');
}

init();